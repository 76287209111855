export const htmlToElement = (html) => {
  let div = document.createElement('div')
  html = html.trim() // Never return a text node of whitespace as the result
  div.innerHTML = html
  return div
}

/**
 * Return a dom element from either a dom query string, jQuery object, a dom element or html string
 * https://stackoverflow.com/questions/494143/creating-a-new-dom-element-from-an-html-string-using-built-in-dom-methods-or-pro/35385518#35385518
 *
 * param query should be {}
 */
export const getDom = ( query ) => {

	if( query.jquery ){
		return query[0];
	}

	if( query instanceof HTMLElement ){
		return query;
	}

	if( isHtmlString(query) ){
		var tpl = document.createElement('template');
		tpl.innerHTML = query.trim(); // Never return a text node of whitespace as the result
		return tpl.content.firstChild;
	}

	return document.querySelector(query);
};

export const isHtmlString = (arg) => {
	if( typeof arg === 'string' && arg.indexOf('<') > -1 ){
		return true;
	}
	return false;
}
