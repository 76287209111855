export const queryString = (params) => {
  return Object.keys(params)
    .map((key) => {
      try {
        const value = encodeURIComponent(params[key].toString())
        return `${key}=${value}`  
      } catch (error) {
        return `${key}=${encodeURIComponent(params[key] || '')}`          
      }
    })
    .join('&')
}

export const queryStringToObject = (query) => {
  try {
    const urlParams = new URLSearchParams(query);   
    let obj = {}
    for (const key of urlParams.keys()) {
      obj[key] = urlParams.get(key)
    }
    return obj 
  } catch (error) {
    console.log("error building query string", error)
    return {}
  }
}
