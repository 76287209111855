// app/javascript/packs/components/AxiosHeaders.js
import axios from 'axios'
const csrfToken = document.querySelector('[name=csrf-token]')
const csrfTokenContent = csrfToken && csrfToken.content

const setAxiosHeaders = () => {
  if (csrfTokenContent){
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfTokenContent
  }
}

export const api = axios.create({
  headers: {'X-CSRF-TOKEN': csrfTokenContent}
});

export default setAxiosHeaders;
